<template>
    <Navbar />
    <main class="container-- bg-white">
        
        <router-view></router-view>
        
    </main>
</template>
<script>
import Navbar from '../components/Navbar.vue';
import Saudacao from '../components/Saudacao.vue';
export default {
    components: {
        Navbar,
        Saudacao
    },
    mounted() {
        document.body.classList.add("default-page");
        document.body.classList.add("bg-light");
    },
    unmounted() {
        document.body.classList.remove("default-page");
        document.body.classList.remove("bg-light");
    }
};
</script>
<style lang="less">
body.default-page {
    padding-top: 56px;
    @media (max-width: 991.98px) {
        .offcanvas-collapse {
            position: fixed;
            top: 56px; /* Height of navbar */
            bottom: 0;
            left: 100%;
            width: 100%;
            padding-right: 1rem;
            padding-left: 1rem;
            overflow-y: auto;
            visibility: hidden;
            background-color: #343a40;
            transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
            &.open {
                visibility: visible;
                transform: translateX(-100%);
            }
        }

    }
    .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
        .nav {
            display: flex;
            flex-wrap: nowrap;
            padding-bottom: 1rem;
            margin-top: -1px;
            overflow-x: auto;
            color: rgba(255, 255, 255, 0.75);
            text-align: center;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
        }
    }
    .nav-underline {
        .nav-link {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            font-size: 0.875rem;
            color: #6c757d;
            &:hover {
                color: #007bff;
            }
            &.active {
                font-weight: 500;
                color: #343a40;
            }
        }
    }
    .bg-purple {
        background-color: #6f42c1;
    }
}
</style>