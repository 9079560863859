import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router';
//import routes from '~pages';
import routes from '~pages'
//import api from '../lib/api';

//import $store from '../store';




export const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


export default async () => {
    
    /** @ts-ignore */
    let { routes }: { routes: RouteRecordRaw[] } = await import('recebimento_produtos_v2/routes').then(e => e.default)
    
    
  
    for (let route of routes) {
        router.addRoute(route)    
    }


    return router
}
