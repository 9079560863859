<template>
    <component :is="layout"></component>
</template>
<script lang="ts" setup>

import { auth_state } from '@/store/auth';
import { computed } from 'vue';
import { AuthState } from './store/auth';

import DefaultLayout from '@/layouts/Default.vue';
import LoginLayout from '@/layouts/Login.vue';
import LoadingLayout from '@/layouts/LoadingLayout.vue'

const layout = computed(() => {
    switch (auth_state.value) {
        case AuthState.AUTHENTICATED:
            return DefaultLayout
        case AuthState.UNAUTHORIZED:
            return LoginLayout
        default:
            break;
    }
    return LoadingLayout
})

</script>
<style lang="less">

</style>