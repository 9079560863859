<template>
    <div>
        <Logo class="mb-5" />
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { onMounted, onUnmounted } from "vue";
import Logo from "../assets/logo-color.svg";
onMounted(() => {
    document.body.classList.add("login-page");
    document.body.querySelector("div.app").classList.add("form-signin");
})

onUnmounted(() => {
    document.body.classList.remove("login-page");
    document.body.querySelector("div.app").classList.remove("form-signin");
})
</script>
<style lang="less" scoped>
svg {
    font-size: 4rem;
}
</style>
<style lang="less">
body {
    &.login-page {
        text-align: center;
        display: flex;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: #f5f5f5;

        .form-signin {
            width: 100%;
            max-width: 330px;
            padding: 15px;
            margin: auto;

            .form-floating:focus-within {
                z-index: 2;
            }
        }
    }
}
</style>