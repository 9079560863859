<template>
    <form @submit.stop.prevent="signin">
        <div class="form-floating">
            <input type="text" class="form-control" required v-model="email" placeholder="Cod Filial" />
            <label for="email">Cod Filial</label>
        </div>
        <div class="form-floating">
            <input type="password" class="form-control" required v-model="senha" placeholder="Senha" />
            <label for="senha">Senha</label>
        </div>
        <button class="w-100 btn btn-lg btn-primary mt-2" type="submit" v-if="!loading">
            Entrar
        </button>
        <button class="w-100 btn btn-lg btn-primary mt-2" type="submit" v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Carregando...
        </button>
        <!--
            <p class="mt-4">
                <router-link to="/login/recover">Esqueci minha senha</router-link>
            </p>
        -->
    </form>
</template>
<script lang="ts" setup>
//import axios from "axios";
import api from '@/lib/api';
import { singIn } from '@/store/auth';
import { isAxiosError } from 'axios';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const email = ref<string>('')
const senha = ref<string>('')
const loading = ref<boolean>(false)
const router = useRouter()

const signin = async () => {
    try {
        loading.value = true
        await singIn(email.value, senha.value)

        router.replace('/')
    } catch (error) {
        let text = "Não foi possível enviar o formulário"
        if (isAxiosError(error)) {
            text = error.response.data.error
        } else if (error instanceof Error) {
            text = error.message
        }
        Swal.fire({
            title: "Oops",
            icon: "error",
            text
        })
    }
    loading.value = false
}


</script>
<style lang="less" scoped>
input[type="email"],
input[type=text] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

input[type="password"] {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

select {
    margin-bottom: -1px;
    border-radius: 0;
}
</style>