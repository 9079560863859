<template>
    <nav
        class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary"
        aria-label="Main navigation"
    >
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                <Logo class="logo" />
            </router-link>
            <button
                class="navbar-toggler p-0 border-0"
                type="button"
                id="navbarSideCollapse"
                aria-label="Toggle navigation"
                @click="toggleMenu"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div
                class="navbar-collapse offcanvas-collapse"
                :class="{ open: showMenu }"
                id="navbarsExampleDefault"
            >
                <Menu/>

                <button class="btn text-white" @click="signOut">Sair</button>
            </div>
        </div>
    </nav>
</template>
<script lang="ts" setup>

import { signOut  } from '@/store/auth';

import Logo from '../assets/logo.svg'
import Menu from '../components/Menu.vue'
import { ref } from 'vue';

const showMenu = ref<boolean>(false)

const toggleMenu = () => {
    showMenu.value = !showMenu.value
}


</script>